import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit'
import axios from 'axios'
import { setMyScheduleFilter, setCancelTokenSource } from '.'
import {
  getEExaminationMySchedule,
  getMyClassList,
} from 'src/services/class/list'
import _ from 'lodash'

export const listenerMiddlewareMyClassList = createListenerMiddleware()

listenerMiddlewareMyClassList.startListening({
  matcher: isAnyOf(setMyScheduleFilter),
  effect: async (action, listenerApi) => {
    const {
      search,
      status,
      schedule,
      page,
      isFetch,
      classStatus,
      classType,
      classCourse,
      courseName,
      finishDate,
      startDate,
    } = listenerApi.getState().classList.myScheduleFilterBody
    const { tab, cancelTokenSource } = listenerApi.getState().classList
    const newCancelTokenSource = axios.CancelToken.source()

    if (!_.isEmpty(cancelTokenSource)) {
      cancelTokenSource.cancel('Request canceled due to a new request in my class list page.')
    }
    listenerApi.dispatch(setCancelTokenSource(newCancelTokenSource))

    let body = {
      limit: 20,
      order: 'DESC',
      page: page,
      sort: 'createdAt',
      cancelToken: newCancelTokenSource.token,
    }
    if (tab === 'MY_SCHEDULE') {
      body = {
        ...body,
        search: search,
        status: status,
        schedule: schedule,
      }
    } else {
      body = {
        page: page,
        classType: classType,
        classCourse: classCourse,
        search: search,
        courseName: courseName,
        classStatus: classStatus,
        finishDate: finishDate,
        startDate: startDate,
        cancelToken: newCancelTokenSource.token,
      }
    }

    if (isFetch) {
      if (tab === 'MY_SCHEDULE') {
        await listenerApi.dispatch(getEExaminationMySchedule(body))
      } else {
        await listenerApi.dispatch(getMyClassList(body))
      }
    }
  },
})
