export const listType = {
  view: 'view',
  edit: 'edit',
  create: 'create',
}

export const listBreadcrumbs = (uuid, type, fullName, userType) => {
  const labelText = type == listType.view ? fullName : 'เพิ่มสมาชิกในทีม'
  return [
    {
      isLink: true,
      color: 'text.white',
      label: userType === 'PROSPECT' ? 'โปรไฟล์' : 'โปรไฟล์ทีม',
      link: userType === 'PROSPECT' ? '/my-profile' : '/manage-prospect',
    },
    {
      isLink: false,
      color: 'text.white',
      label: uuid && type != listType.view ? 'แก้ไขประวัติสมาชิก' : labelText,
    },
  ]
}
export const manageProspectCells = [
  {
    id: 'courseCode',
    label: 'รหัสหลักสูตร',
    width: 160,
  },
  {
    id: 'courseName',
    label: 'หลักสูตร',
    width: 240,
  },
  {
    id: 'statusClass',
    label: 'สถานะคลาส',
    width: 200,
  },
  {
    id: 'classDateAndTime',
    label: 'วันที่เริ่มอบรม - วันสิ้นสุดการอบรม',
    width: 250,
  },
  {
    id: 'testDate',
    label: 'วันสอบ',
  },
  {
    id: 'actionProspect',
    disablePadding: false,
    label: 'คำสั่ง',
    width: 50,
    hideSortIcon: true,
  },
]

export const manageProspectExaminationCells = [
  {
    id: 'examType',
    label: 'ประเภทสนามสอบ',
  },
  {
    id: 'location',
    label: 'สถานที่สอบ',
    width: 200,
  },
  {
    id: 'region',
    label: 'ภูมิภาค',
  },
  {
    id: 'registerDateTime',
    label: 'วันที่สมัคร',
  },
  {
    id: 'examDateTime',
    label: 'วันที่และเวลาที่สอบ',
  },
  {
    id: 'examStatus',
    label: 'สถานะ',
  },
  {
    id: 'examResult',
    label: 'ผลการสอบ',
  },
  {
    id: 'actionExam',
    disablePadding: false,
    label: 'คำสั่ง',
    width: 50,
    hideSortIcon: true,
  },
]

export const optionPrefixTH = [
  { label: 'นาย', value: 'นาย' },
  { label: 'นางสาว', value: 'นางสาว' },
  { label: 'นาง', value: 'นาง' },
]

export const optionPrefix = [
  { label: 'Mr', value: 'Mr' },
  { label: 'Ms', value: 'Ms' },
  { label: 'Mrs', value: 'Mrs' },
]

export const PREFIXS = [
  { th: 'นาย', en: 'Mr' },
  { th: 'นางสาว', en: 'Ms' },
  { th: 'นาง', en: 'Mrs' },
]

export const historyCells = [
  {
    id: 'courseCode',
    label: 'รหัสหลักสูตร',
    width: 150,
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'courseName',
    label: 'หลักสูตร',
    width: 200,
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'author',
    label: 'ผู้ทำรายการ',
    width: 200,
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'event',
    label: 'รายการ',
    width: 150,
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'dateTime',
    label: 'วันเวลาที่สมัคร',
    width: 150,
    disablePadding: false,
    hideSortIcon: true,
  },
]

export const examinationHistoryCells = [
  {
    id: 'author',
    label: 'ผู้ทำรายการ',
    width: 200,
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'event',
    label: 'รายการ',
    width: 150,
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'createdAt',
    label: 'วันเวลาที่สมัคร',
    width: 150,
    disablePadding: false,
    hideSortIcon: true,
  },
]
