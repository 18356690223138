import React, { useEffect } from 'react'
import { useDispatch, shallowEqual, useSelector } from 'react-redux'
import { Container } from '@mui/material'
import Template from 'src/components/Template'
import { resetSearchData, setFilterValue } from 'src/redux/slices/eContent'
import { fetchDataList } from '../List/events'
import { ECONTENT_TYPE } from '../model'
import TabContent from '../List/AllArticle/TabContent'
import Header from './Header'
import { getOptions } from 'src/services/eContent'

const EContentSearch = () => {
  const dispatch = useDispatch()
  const { filter, page, order, isFetchSearch } = useSelector(
    (state) => ({
      filter: state.eContent.filter,
      page: state.eContent.filter.page,
      order: state.eContent.filter.order,
      isFetchSearch: state.eContent.isFetchSearch,
    }),
    shallowEqual
  )
  useEffect(() => {
    dispatch(getOptions())
    dispatch(resetSearchData())
    return () => dispatch(setFilterValue({ key: 'keyword', value: '' }))
  }, [])
  useEffect(() => {
    if (isFetchSearch) {
      dispatch(
        fetchDataList({
          ...filter,
          type: [ECONTENT_TYPE.E_CONTENT, ECONTENT_TYPE.KB],
        })
      )
    }
  }, [page, order, isFetchSearch])
  return (
    <Container maxWidth="lg">
      <Header />
      <Template maxWidth="100%">
        <TabContent />
      </Template>
    </Container>
  )
}

export default EContentSearch
