import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'
import Container from '@mui/material/Container'
import {
  resetRelatedList,
  setIsFetch,
  stopPrint,
} from 'src/redux/slices/eContent'
import Header from './Header'
import BottomTagLine from '../BottomTagLine'
import { SeeAlsoBar } from '../SeeAlsoBar'
import Content from './Content'
import {
  fetchContentData,
  fetchRelatedContent,
  handleCreateDownloadEvent,
} from './events'
import { ECONTENT_TYPE_TH_TEXT } from '../model'

const Detail = ({ type }) => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const { contentData, isFetch, excludeId, totalCount } = useSelector(
    (state) => ({
      contentData: state.eContent.contentData,
      isFetch: state.eContent.isFetch,
      excludeId: state.eContent.related.excludeId,
      totalCount: state.eContent.totalCount,
    }),
    shallowEqual
  )
  const contentToPrint = useRef(null)
  const handlePrint = useReactToPrint({
    content: () => contentToPrint.current,
    documentTitle: `${ECONTENT_TYPE_TH_TEXT.KB} ${contentData?.name}`,
    onBeforePrint: () => console.log('before printing...'),
    onAfterPrint: () => {
      console.log('after printing...')
      dispatch(handleCreateDownloadEvent(id))
      dispatch(stopPrint())
    },
    removeAfterPrint: false,
  })
  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(resetRelatedList())
    dispatch(fetchContentData(id))
    return () => dispatch(resetRelatedList())
  }, [id])
  useEffect(() => {
    //scroll to loadmore related content
    if (contentData?.uuid && isFetch) {
      dispatch(fetchRelatedContent(contentData, excludeId))
    }

    const handleWindowScroll = () => {
      const footer = document.getElementById('page-footer')

      if (totalCount > 0 && !isFetch) {
        if (
          window.innerHeight + Math.round(window.scrollY) >
          document.body.offsetHeight - footer.offsetHeight
        ) {
          dispatch(setIsFetch(true))
        }
      }
    }
    window.addEventListener('scroll', handleWindowScroll)
    return () => {
      window.removeEventListener('scroll', handleWindowScroll)
    }
  }, [contentData, isFetch, totalCount])
  return (
    <Container
      maxWidth="lg"
      sx={{ '@media print': { marginTop: '0px' }, marginTop: '72px' }}
      ref={contentToPrint}
    >
      <Header type={type} handlePrint={handlePrint} />
      <Content />
      <BottomTagLine Tag={contentData?.eContentTag} />
      <SeeAlsoBar />
    </Container>
  )
}

export default Detail
