import React, { useState } from 'react'
import { useDispatch, shallowEqual, useSelector } from 'react-redux'
import { useTheme } from '@mui/material'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import FilterListIcon from '@mui/icons-material/FilterList'
import { Column, Row } from 'src/components/Layout/Styled'
import SearchCustom from 'src/components/Search'
import FilterDrawer from '../Filter/FilterDrawer'
import { isMediaWidthMoreThan } from 'src/utils/lib'
import { setFilterValue, setIsFetchSearch } from 'src/redux/slices/eContent'
import { ECONTENT_TYPE } from '../model'

const Header = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const [isOpen, setOpen] = useState(false)
  const isNotSm = isMediaWidthMoreThan('sm')
  const { filter } = useSelector(
    (state) => ({
      filter: state.eContent.filter,
    }),
    shallowEqual
  )
  return (
    <Column
      sx={{
        mt: 9,
        py: 2,
        [theme.breakpoints.down('sm')]: {
          py: 3,
          borderBottom: '0px',
        },
      }}
    >
      <Typography variant={isNotSm ? 'h4' : 'h5'} sx={{ mb: 3 }}>
        คลังความรู้
      </Typography>
      <Row>
        <SearchCustom
          boxSx={{
            [theme.breakpoints.up('lg')]: {
              width: '100%',
            },
          }}
          placeholder="ค้นหา"
          text={filter.keyword}
          setText={(value) => {
            dispatch(setFilterValue({ key: 'keyword', value: value }))
          }}
          handleSearch={() => {
            dispatch(setFilterValue({ key: 'page', value: 1 }))
            dispatch(setIsFetchSearch(true))
          }}
        />
        <IconButton
          id="btn-filter"
          data-testid="btn-filter"
          size="small"
          color="primary"
          sx={{ position: 'relative' }}
          onClick={() => setOpen(true)}
        >
          <FilterListIcon />
        </IconButton>
      </Row>
      <FilterDrawer
        isOpen={isOpen}
        setOpen={setOpen}
        type={[ECONTENT_TYPE.E_CONTENT, ECONTENT_TYPE.KB]}
      />
    </Column>
  )
}

export default Header
