import React from 'react'
import _ from 'lodash'
import Button from '@mui/material/Button'
import { isMediaWidthMoreThan } from 'src/utils/lib'
import { useHistory } from 'react-router-dom'
import ArrowForward from '@mui/icons-material/ArrowForward'
import { handleToDetail } from './events'

const ContainedButton = ({ data }) => {
  const history = useHistory()
  const isLg = isMediaWidthMoreThan('lg')
  const status = _.get(data, 'classStatus', '')
  const uuid = _.get(data, 'trainingPlanUuid', '')
  const classLearnerUuid = _.get(data, 'classLearnerUuid', '')

  switch (status) {
    case 'INELIGIBLE':
    case 'CANCEL':
    case 'CANCELED':
    case 'TIME_OUT':
    case 'WAITING_CHECK':
    case 'WAITING_ANNOUNCE':
    case 'CLASS_CLOSED_PASS':
    case 'CLASS_CLOSED_NOT_PASS':
    case 'CLASS_CANCELED':
    case 'CANCEL_CLASS':
      return (
        <Button
          sx={{ px: '22px', minWidth: 150, height: '100%' }}
          size={isLg ? 'l' : 'm'}
          variant="contained"
          color="secondary"
          endIcon={<ArrowForward />}
          data-testid="btn-check"
          fullWidth
          onClick={() => handleToDetail(data, history, true)}
        >
          ตรวจสอบผล
        </Button>
      )
    case 'WAITING_DOCUMENT':
      return (
        <Button
          sx={{ px: '22px', minWidth: 132, height: '100%' }}
          size={isLg ? 'l' : 'm'}
          variant="contained"
          color="secondary"
          endIcon={<ArrowForward />}
          data-testid="btn-doc"
          onClick={() =>
            history.push(`/upload-document/${uuid}?id=${classLearnerUuid}`)
          }
          fullWidth
        >
          ส่งเอกสาร
        </Button>
      )
    default:
      return (
        <Button
          sx={{ px: '22px', minWidth: 156, height: '100%' }}
          size={isLg ? 'l' : 'm'}
          variant="contained"
          color="secondary"
          endIcon={<ArrowForward />}
          data-testid="btn-class"
          onClick={() => handleToDetail(data, history)}
          fullWidth
        >
          ไปที่คลาสเรียน
        </Button>
      )
  }
}
export default ContainedButton
